import type { User } from '@firebase/auth';
import { FirebaseError as OriginalFirebaseError } from '@firebase/util';
import type { UserProfile } from '~/modules/shared/stores/user-store';
import { useUserStore } from '~/modules/shared/stores/user-store';
import { authSdk } from '~/internals/auth/auth-sdk.ts';
import { useGraphqlMutation } from '~/_composables/vue-graphql.ts';
import { UpdatePhoneDocument, type UpdatePhoneMutationVariables, type UpdateProfileMutationVariables } from '~/api/user.generated.ts';
import { GetMeDocument, UpdateProfileDocument } from '~/api/user.generated.ts';
import { FirebaseError } from '~/internals/errors/firebase-error.ts';
import { clientHasura } from '~/internals/hasura/client-hasura.ts';
import useNavigate from '~/_composables/useNavigate.ts';
import useNotification from '~/_composables/useNotification.ts';
import { handleError } from '~/internals/errors/base-error.ts';

function useUserAction() {
  const userStore = useUserStore();
  const noti = useNotification();

  async function hasStoredSession() {
    return authSdk.hasStoredSession();
  }

  async function reclaimSession() {
    const token = await authSdk.getAccessToken();
    if (token) {
      const isSignedIn = await authSdk.isSignedIn(true);
      if (isSignedIn) {
        userStore.user = await fetchProfile();
      } else {
        // firebase session is expired
        await authSdk.signOut();
      }
    }
  }

  async function fetchProfile(firebaseProfile?: User): Promise<UserProfile | null> {
    try {
      userStore.isFetchingProfile = true;
      // profile is a combination of email from firebase and some info from BE
      const [firebaseUser, userFromServer] = await Promise.all([
        // if pass user profile, reuse it
        firebaseProfile || authSdk.getUser<User>(),
        clientHasura.request(GetMeDocument),
        // clientHasura.request(GetUserDistrictManagementsDocument),
      ]);

      if (!firebaseUser) {
        return null;
      }

      const myProfile = userFromServer.me[0];

      userStore.fetchProfileError = null;

      userStore.userPermission = userFromServer.user_district_managements.filter((item) => item.user_id === myProfile.id);

      return {
        email: firebaseUser.email,
        ...myProfile,
      };
    } catch (e) {
      userStore.fetchProfileError = e as Error;
      throw e;
    } finally {
      userStore.isFetchingProfile = false;
    }
  }

  async function signInWithPassword({ email, password }: { email: string; password: string }) {
    try {
      const resp = await authSdk.with('firebase').signIn({ email, password });
      const user = await fetchProfile(resp.user);
      userStore.user = user;
      userStore.authError = null;
    } catch (e) {
      if (e instanceof OriginalFirebaseError) {
        const err = e;
        userStore.authError = err;
        throw new FirebaseError({
          message: err.message,
          method: 'signIn',
          code: err.code,
        });
      }

      throw e;
    }
  }

  async function refreshProfile() {
    try {
      userStore.user = await fetchProfile();
    } catch (e) {
      throw e;
    }
  }

  async function signInWithRedirect({ provider, redirectUrl }: { provider: 'google'; redirectUrl?: string }) {
    try {
      await authSdk.with('firebase').signInWithRedirect({ redirectUrl, provider });
    } catch (e) {
      if (e instanceof OriginalFirebaseError) {
        const err = e;
        userStore.authError = err;
        throw new FirebaseError({
          message: err.message,
          method: 'signUp',
          code: err.code,
        });
      }

      throw e;
    }
  }

  const { mutateAsync: updateProfile } = useGraphqlMutation(UpdateProfileDocument, {
    auth: true,
  });

  const { mutateAsync: updatePhone } = useGraphqlMutation(UpdatePhoneDocument, {
    auth: true,
  });

  async function updateUserProfile(req: UpdateProfileMutationVariables) {
    await updateProfile(req);
  }

  async function updateUserPhone(req: UpdatePhoneMutationVariables) {
    await updatePhone(req);
  }

  async function signUp(
    {
      email,
      password,
      fullName,
    }: {
      email: string;
      password: string;
      fullName: string;
    },
    options: { onExists?: () => void },
  ) {
    try {
      const resp = await authSdk.with('firebase').signUp({
        email,
        password,
      });
      await updateUserProfile({
        name: fullName,
        phone_number: resp.user.phoneNumber,
      });
      try {
        userStore.user = await fetchProfile(resp.user);
      } catch (e) {
        userStore.fetchProfileError = e as Error;
        throw e;
      }
    } catch (e) {
      if (e instanceof OriginalFirebaseError) {
        const err = e;
        const firebaseErr = new FirebaseError({
          message: err.message,
          method: 'signUp',
          code: err.code,
        });
        if (err.code === 'auth/email-already-in-use') {
          noti.warn({
            summary: handleError(firebaseErr) ?? 'Không xác định',
          });
          options.onExists?.();
        }

        throw firebaseErr;
      }

      throw e;
    }
  }
  async function signOut({ redirectTo }: { redirectTo?: string } = {}) {
    await authSdk.signOut();
    if (redirectTo) {
      window.location.href = redirectTo;
    } else {
      window.location.reload();
    }
    userStore.$reset();
  }

  async function handleRedirectResult() {
    const resp = await authSdk.with('firebase').handleRedirectCallback();

    if (!resp) {
      return;
    }

    userStore.user = await fetchProfile(resp.credential.user);

    if (resp.redirectUrl) {
      await useNavigate().navigate(resp.redirectUrl);
    }
  }

  function hasRedirectResult() {
    return authSdk.with('firebase').hasRedirectBack();
  }

  async function changePassword({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) {
    try {
      await authSdk.with('firebase').changePassword({
        email: userStore.user?.email ?? '',
        oldPassword,
        newPassword,
      });
    } catch (e) {
      throw e;
    }
  }

  async function sendVerifyCodePhoneNumber(phoneNumber: string) {
    try {
      return await authSdk.with('firebase').handleVerifyPhoneNumber(phoneNumber);
    } catch (e) {
      throw e;
    }
  }

  async function updatePhoneNumber({ code, verificationId, phoneNumber }: { code: string; verificationId: string; phoneNumber: string }) {
    try {
      await authSdk.with('firebase').handleUpdatePhoneNumber({ code, verificationId });
      await updateUserPhone({
        phone_number: phoneNumber,
      });
      await refreshProfile();
    } catch (e) {
      throw e;
    }
  }

  async function sendPasswordResetEmail(email: string) {
    try {
      await authSdk.with('firebase').sendMailForgotPassword(email);
    } catch (e) {
      throw e;
    }
  }

  async function confirmPasswordReset(params: { oobCode: string; newPassword: string }) {
    try {
      await authSdk.with('firebase').handleConfirmPasswordReset(params);
    } catch (e) {
      throw e;
    }
  }

  return {
    reclaimSession,
    signInWithPassword,
    signInWithRedirect,
    updateUserProfile,
    hasStoredSession,
    signUp,
    signOut,
    handleRedirectResult,
    hasRedirectResult,
    refreshProfile,
    changePassword,
    sendPasswordResetEmail,
    confirmPasswordReset,
    sendVerifyCodePhoneNumber,
    updatePhoneNumber,
  };
}

export default useUserAction;
