import type { HandledError } from '~/internals/errors/base-error.ts';
import { safeStringify } from '~/common/json.ts';

export function getFirebaseErrorMsg(errCode = '') {
  switch (errCode) {
    case 'auth/invalid-email':
      return 'Email không hợp lệ';
    case 'auth/user-disabled':
      return 'Tài khoản đã bị khóa';
    case 'auth/user-not-found':
      return 'Không tìm thấy người dùng';
    case 'auth/email-already-in-use':
      return 'Email đã sử dụng';
    case 'auth/wrong-password':
      return 'Sai mật khẩu';
    case 'auth/quota-exceeded':
      return 'Quá số lần thử đăng nhập';
    // sendPasswordResetEmail
    case 'auth/missing-android-pkg-name':
      return 'Thiếu gói android';
    case 'auth/missing-continue-uri':
      return 'Thiếu địa chỉ URL tiếp tục';
    case 'auth/missing-ios-bundle-id':
      return 'Thiếu ios bundle id';
    case 'auth/invalid-continue-uri':
      return 'Địa chỉ URL tiếp tục không hợp lệ';
    case 'auth/unauthorized-continue-uri':
      return 'Không được phép sử dụng địa chỉ URL tiếp tục';
    case 'auth/too-many-requests':
      return 'Quá nhiều yêu cầu';
    case 'auth/invalid-action-code':
      return 'Mã hành động không hợp lệ';
    default:
      return 'Lỗi không xác định';
  }
}

export class FirebaseError extends Error implements HandledError {
  public code?: string;
  name = 'FirebaseError';
  method: string;

  constructor(options: { message?: string; code?: string; method: string }) {
    super(options.message || 'Unknown Error');
    this.method = options.method;
    this.code = options.code;
  }

  toString() {
    return safeStringify({
      message: this.message,
      code: this.code,
    });
  }

  _handle() {
    return getFirebaseErrorMsg(this.code);
  }
}
